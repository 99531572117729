var init = function init() {
  var feedbackButton = document.getElementById("feedback-button");
  var feedbackModal = document.getElementById("feedback-modal");
  var feedbackModalBackground = document.getElementById(
    "feedback-modal-background"
  );
  var feedbackModalCloseButton = document.getElementById(
    "feedback-modal-close"
  );

  var closeFeedback = function closeFeedback(event) {
    event.preventDefault();
    feedbackModal.classList.remove("is-active");
  };

  feedbackButton.addEventListener("click", function(event) {
    event.preventDefault();
    feedbackModal.classList.add("is-active");
  });

  feedbackModalBackground.addEventListener("click", closeFeedback);
  feedbackModalCloseButton.addEventListener("click", closeFeedback);
};
window.addEventListener("load", init, false);
